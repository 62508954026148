:root {
  --main-color: #0d1450;
  --second-color: #b7b5b3;
  --three-color: #690501;
  --four-color: #0a0c38;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,600;0,700;0,800;1,100;1,200;1,300;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  font-family: "Poppins", "Oxygen", sans-serif;

  box-sizing: border-box;
  color: #0a0c38;
}

code {
  font-family: "Poppins", "Oxygen", sans-serif;
}
