.homeBox {
  display: flex;

  margin: 20px auto;
  width: 800px;
  background-color: var(--second-color);
  opacity: 0.95;
  min-height: 460px;
}

.homeAccordinBox {
  width: 300px;
  padding: 10px;
  margin: 20px auto;
  height: 400px;
  overflow-y: scroll;
}

.homeCardBox {
  width: 400px;
  margin: 10px auto;
}

@media (max-width: 800px) {
  .homeBox {
    flex-direction: column-reverse;
    width: 400px;
    padding-bottom: 50px;
  }
  .homeAccordinBox {
    width: 300px;
    margin: 0 auto;
  }
  .homeCardBox {
    width: 300px;

    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .homeBox {
    flex-direction: column;
    padding: 5px;
    padding-bottom: 50px;
    width: 340px;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
