.card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0.35rem;
  box-shadow: 0 0 5px 5px #00000080;
  background-color: #f5f5f5f8;
  transform-style: preserve-3d;
  transition: 200ms;
  cursor: pointer;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  height: 410px;
  width: 350px;
  margin: 15px auto;
}

.card:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px #00000080;
}

.card.flip {
  --rotate-y: 180deg;
}

.card.front {
  left: 0;
}

.card .front,
.card .back {
  position: absolute;
  padding: 1rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateX(0deg);
}

.card .back {
  transform: rotateY(180deg);
}

.card-id {
  font-size: 36px;
  text-align: center;
  margin: 0;
}

.card-tool-box1 {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.card-tool-box2 {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.description {
  height: 350px;
  width: 300px;
  position: relative;
  padding: 5px;
  text-align: center;
}

.desc {
  height: 250px;
  font-size: 16px;
}

.cardlogo {
  height: 42px;
}

@media (max-width: 800px) {
  .card {
    width: 300px;
    height: 450px;
    padding-bottom: 20px;
    margin: 0px auto;
  }
  .description {
    width: 250px;
    padding: 2px;
  }
  .card-tool-box1 {
    display: inline-block;
    position: absolute;
    bottom: -20px;
    left: 10px;
  }
  .card-tool-box2 {
    display: inline-block;
    position: absolute;
    bottom: -20px;
    right: 10px;
  }
}

@media (max-width: 600px) {
  .card {
    padding: 20px 0px 20px 0px;
    padding-bottom: 20px;
    margin: 0 auto;
  }
  .description {
    width: 250px;
    padding: 2px;
  }

  .card-grid {
    margin: 0 auto;
  }
  .desc {
    height: 210px;
  }
}
