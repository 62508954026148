.app-Box {
  min-height: 92vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  margin-top: 62px;
  height: fit-content;
}

@media screen and (max-width: 800px) {
  .app-Box {
    min-height: 92vh;
    height: fit-content;
    margin-top: 56px;
  }
}
